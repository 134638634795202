import React, { useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";

import { useHistory } from "react-router";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";

import close from "../../../assets/cancel-outline-chip.svg";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

const Image = styled.img`
  display: block;
  width: 20px;
`;

const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 1;
  &:hover {
    cursor: pointer;
  }
`;

const ModalContentContainer = styled.div`
  width: 505px;
  margin: auto;
  border-radius: 4px;
  background-color: white;
`;

const Card = styled(Flex)`
  min-width: max-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  media (max-width: 657px) {
    margin-left: 78px;
  }
`;

const FormContainer = styled.div`
  // width: 230px;
`;

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
  margin-bottom: 14px;
`;

const FilterMenuTitle = styled.div`
  margin-bottom: 0px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #0c2945;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 163px;
  height: 38px;
  background-color: ${(props) => props.bg};
  border-radius: 4px;
  border: ${(props) => props.border || 0};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => props.color};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary }) =>
      isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF"};
  }
`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "10px"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;


export const Filters = (props) => {
  const {
    setSelectedFilters,
    selectedFilters,
    showModal,
    onClose,
    filterValuesForRequest = {},
    handleSubmit,
    setCurrPageNumber
  } = props;


  const [selectedValues, setSelectedValues] = useState(selectedFilters);

  const faxSentOptions = [
    { value: "true", label: "True", category: "fax_sent" },
    { value: "false", label: "False", category: "fax_sent" },
  ];

  const referrerCommsOption = [
    { value: "true", label: "True", category: "referrer_comms_unsubscribe" },
    { value: "false", label: "False", category: "referrer_comms_unsubscribe" },
  ];

  const referrerPatientCommsOption = [
    { value: "true", label: "True", category: "referrer_patient_comms_unsubscribe" },
    { value: "false", label: "False", category: "referrer_patient_comms_unsubscribe" },
  ];

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const mergedFilters = {
      fax_sent: selectedValues["fax_sent"] || filterValuesForRequest["fax_sent"],
      referrer_comms_unsubscribe: selectedValues["referrer_comms_unsubscribe"] || filterValuesForRequest["referrer_comms_unsubscribe"],
      referrer_patient_comms_unsubscribe: selectedValues["referrer_patient_comms_unsubscribe"] || filterValuesForRequest["referrer_patient_comms_unsubscribe"]

    };
    setCurrPageNumber(1);
    setSelectedFilters(mergedFilters);
    handleSubmit()
    onClose();
  };

    const RadioOption = ({ type, ...props }) => {
      return (
        <>
          <components.Option {...props}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span>
                <input
                  type={"radio"}
                  checked={props.isSelected}
                  onChange={() => null}
                  style={{ margin: 0, padding: 0 }}
                />{" "}
              </span>
              <label style={{ margin: 0, padding: 0 }}>{props.label}</label>
            </div>
          </components.Option>
        </>
      );
    };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const colourStyles = {
    placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isSelected ? "#D9ECFF" : "white",
        display: "flex",
      };
    },
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  const closeSelected = (category, selectedItem) => {
    const filters = selectedValues[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedValues,
      [category]: filters.length > 0 ? filters : [],
    };
    setCurrPageNumber(1);
    setSelectedValues(newFilters);
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <FilterMenuTitle>Filters </FilterMenuTitle>
            <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
          </div>

          <div style={{ width: "100%" }}>
            <FormContainer>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <Row>
                    <SelectContainer>
                      <SelectDropdownTitle>Fax Sent</SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={faxSentOptions}
                        value={selectedValues["fax_sent"]}
                        components={{
                          Option:RadioOption,
                          IndicatorsContainer,
                        }}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            fax_sent: [value],
                          })
                        }
                        styles={colourStyles}
                      />
                    </SelectContainer>

                    <SelectContainer>
                      <SelectDropdownTitle>Referrer Comms Unsubscribe</SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={referrerCommsOption}
                        value={selectedValues["referrer_comms_unsubscribe"]}
                        components={{
                          Option:RadioOption,
                          IndicatorsContainer,
                        }}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            referrer_comms_unsubscribe: [value],
                          })
                        }
                        styles={colourStyles}
                      />
                    </SelectContainer>
                  </Row>
                  <Row>
                    <SelectContainer>
                      <SelectDropdownTitle>Referrer Patient Unsubscribe</SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={referrerPatientCommsOption}
                        value={selectedValues["referrer_patient_comms_unsubscribe"]}
                        components={{
                          Option:RadioOption,
                          IndicatorsContainer,
                        }}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            referrer_patient_comms_unsubscribe: [value],
                          })
                        }
                        styles={colourStyles}
                      />
                    </SelectContainer>

                  </Row>

                  <Row
                    style={{
                      justifyContent: "flex-end",
                      marginBottom: "20px",
                      gap: "6px",
                      flexWrap: "wrap",
                      maxWidth: "500px",

                    }}
                  >
                    {Object.values(selectedValues).map((item, index) => {
                      if (!item || !Array.isArray(item)) return null;
                      return item.map((i, idx) => (
                        <SelectedItemContainer key={index + idx}>
                          <SelectedItem>
                            {" "}
                            {i.category
                              .replace(/-/g, " ")
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, function (char) {
                                return char.toUpperCase();
                              })}{" "}
                            :{" "}
                            {i?.label?.replace(/\b\w/g, function (char) {
                              return char.toUpperCase();
                            })}
                          </SelectedItem>
                          <CloseSign
                            onClick={() => closeSelected(i.category, i)}
                          >
                            <Image src={close} alt={"close sign"} />
                          </CloseSign>
                        </SelectedItemContainer>
                      ));
                    })}
                  </Row>
                  <Row justifyContent="flex-end" gap="18px">
                    <Button
                      color="#000000"
                      border="2px solid #CFCFCF"
                      bg="#FFF"
                    >
                      Cancel
                    </Button>
                    <Button
                      bg="#00AEEF"
                      color="#FFFFFF"
                      isPrimary={true}
                      disabled={false}
                    >
                      Apply Filters
                    </Button>
                  </Row>
                </div>
              </form>
            </FormContainer>
          </div>
        </Card>
      </ModalContentContainer>
    </Modal>
  );
};
