import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { Arrow } from './Icons';
import columnIcon from '../../../assets/columnIcon.svg';
import { Popover } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  position: relative;
`;

const RecoNoteIconContainer = styled.span`
  cursor: pointer;
`;

const Dropdown = styled.div`
  // position: absolute;
  background: white;
  padding: 20px;
  top: 30px;
  min-width: 225px;
  z-index: 3;
  max-height:300px;
  overflow:auto;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items:center;
  gap:12px;
  margin-bottom:14px;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};  
  transform: ${(props) => (props.isDragging ? 'translateY(-5px)' : 'none')};
  transition: transform 0.2s ease;

  &:last-child {
    margin-bottom: 0;
  }

`;

export default function ColumnFilterDropdown({
  columnCheckbox,
  columnData,
  isOpen,
  handleColumnChange,
  handleColumnFilterDropdown,
  setColsOrder=()=>{},
  colOrderKey='col-order'
}) {

  let storedCols = JSON.parse(localStorage.getItem(colOrderKey)) || []
  const [columns, setColumns] = useState( storedCols.length>0? storedCols: Object.keys(columnCheckbox));

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
    const updatedColumns = [...columns];
    const [draggedColumn] = updatedColumns.splice(draggedIndex, 1);
    updatedColumns.splice(index, 0, draggedColumn);
    setColumns(updatedColumns);
    setColsOrder(updatedColumns)
    localStorage.setItem(colOrderKey,JSON.stringify(updatedColumns))
  };
  

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleColumnFilterDropdown((prev) => !prev);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  

  return (
    <Container>
      <span>Columns</span>
      <RecoNoteIconContainer
        onClick={(e) => {
          handleColumnFilterDropdown((prev) => !prev);
          handleClick(e)
        }}
      >
        <Arrow />
      </RecoNoteIconContainer>

      {isOpen && (
          <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -10,
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: 8,
              border: '1px solid #C2CCD9'

            }
          }}
        >
              <Dropdown>
          {columns.map((column, index) => (
            <DropdownItem
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            draggable
              key={column}
            >
              <img src={columnIcon} />
              <input
                onChange={handleColumnChange}
                type="checkbox"
                value={column}
                checked={columnCheckbox[column]}
              />
              <label style={{ marginBottom: '0px', fontWeight:600, fontSize:12,color:'#636363' }}>
                {columnData?.find((col) => col.key === column)?.name}
              </label>
            </DropdownItem>
          ))}
        </Dropdown>
        </Popover>
    
      )}
    </Container>
  );
}