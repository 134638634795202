import React, { useState } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { Spinner } from "react-bootstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as NoDataGroup } from "../../assets/noDataGroup.svg";
import { Collapse, IconButton } from "@material-ui/core";

const TableContainer = styled.table`
  width: 100%;
  overflow: auto;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: ${(props) => !props.paginated && "1px solid #c2ccd9"};
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: ${(props) => !props.paginated && "8px"};
  padding: ${(props) => !props.paginated && "15px"};
  height: ${(props) => (props.paginated ? "16rem" : "30rem")};
  overflow: auto;
`;

const TableCell = styled.td`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: ${(props) => props.align};
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  border: none;
  padding-left: 0;
  &:first-child {
    padding-left: 16px;
  }
`;

const TableHeaderCell = styled(TableCell)`
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  font-weight: 600;
  font-size: 14px;
  color: #9fa2b4;
  padding: 3px 0px;
`;

const TableRow = styled.tr`
  border: none;
  &:hover {
    background: #f9fafc;
    transition: all 0.3s ease;
  }
`;

const HeaderContainer = styled.span`
  width: 100%;
  display: block;
  padding: 4px;
  border-radius: 10px;
  padding-left: 0px;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const SubTableContainer = styled.div`
  padding: 16px;
  background: #f9f9f9;
  border-top: 1px solid #e6e9f1;
`;

const DetailsTable = ({
  rows,
  columns,
  isLoadingRow,
  isPaginated,
  subRowKey = "",
  subColumns = [],
  hasSubTable = false,
}) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowIndex) => {
    setExpandedRows((prev) => ({ ...prev, [rowIndex]: !prev[rowIndex] }));
  };

  return (
    <Card paginated={isPaginated}>
      <TableContainer>
        <thead>
          <tr style={{ background: "#e6e9f1" }}>
            {
              hasSubTable && <th />
            }
            
            {columns.map((column, i) => (
              <TableHeaderCell
                width={column.width}
                maxWidth={column.maxWidth}
                key={JSON.stringify(column + i)}
                align="left"
              >
                <HeaderContainer>{column.name}</HeaderContainer>
              </TableHeaderCell>
            ))}
          </tr>
        </thead>

        {!isLoadingRow && (
          <tbody>
            {rows &&
              rows.map((row, idx) => (
                <React.Fragment key={JSON.stringify(row + idx)}>

                  <TableRow>
                    {hasSubTable && (
                      <TableCell>
                        <IconButton size="small" onClick={() => toggleRow(idx)}>
                          {expandedRows[idx] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    )}

                    {columns.map((column, i) => (
                      <TableCell key={JSON.stringify(column + i)} align="left">
                        {column.displayCell
                          ? column.displayCell(row[column.key], row, idx)
                          : row[column.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                  {hasSubTable ? (
                    <TableRow>
                      <td
                        colSpan={columns.length + 1}
                        style={{ padding: 0, border: "none" }}
                      >
                        <Collapse
                          in={expandedRows[idx]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <SubTableContainer>
                            {row[subRowKey] && row[subRowKey].length > 0 ? (
                              <TableContainer>
                                <thead>
                                  <tr style={{ background: "#e6e9f1" }}>
                                    {subColumns.map((subColumn, i) => (
                                      <TableHeaderCell
                                        key={JSON.stringify(subColumn + i)}
                                        align="left"
                                      >
                                        <HeaderContainer>{subColumn.name}</HeaderContainer>
                                      </TableHeaderCell>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {row[subRowKey].map((subRow, subIdx) => (
                                    <TableRow key={subIdx}>
                                      {subColumns.map((subColumn, i) => (
                                        <TableCell
                                          key={JSON.stringify(subColumn + i)}
                                        >
                                          {subColumn.displayCell
                                            ? subColumn.displayCell(
                                                subRow[subColumn.key],
                                                subRow,
                                                subIdx
                                              )
                                            : subRow[subColumn.key] || "N/A"}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </tbody>
                              </TableContainer>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "#9fa2b4",
                                }}
                              >
                                no data
                              </div>
                            )}
                          </SubTableContainer>
                        </Collapse>
                      </td>
                    </TableRow>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
          </tbody>
        )}
      </TableContainer>

      {!isLoadingRow && rows && isEmpty(rows) && (
        <NoDataContainer>
          <NoDataGroup />
        </NoDataContainer>
      )}

      {isLoadingRow && (
        <SpinnerContainer>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </SpinnerContainer>
      )}
    </Card>
  );
};

export default DetailsTable;
