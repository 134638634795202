import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";

import { useHistory } from "react-router";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  width: 505px;
  margin: auto;
  border-radius: 4px;
  background-color: white;
`;

 const Card = styled(Flex)`
  min-width: max-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  media (max-width: 657px) {
    margin-left: 78px;
  }
`;

const FormContainer = styled.div`
  // width: 230px;
`;

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
  margin-bottom: 30px;
`;
 


const FilterMenuTitle = styled.div`
  margin-bottom: 0px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #0c2945;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 163px;
  height: 38px;
  background-color: ${(props) => props.bg};
  border-radius: 4px;
  border: ${(props) => props.border || 0};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => props.color};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary }) =>
      isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF"};
  }
`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "10px"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
`;

export const DEFAULT_VALUES = {
  location: "",
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ margin: "0 5px", verticalAlign: "middle" }}
        />{" "}
        <label style={{ margin: "0" }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const MessagesFilter = (props) => {
  const {
    setSelectedFilters,
    selectedFilters,
    showModal,
    onClose,
    filterValuesForRequest = {},
    handleSubmit = () => {},
  } = props;

  const dispatch = useDispatch();
  const locationsOptionsList = useSelector(state => state.radAdmin.locationsOptionsList.data)?.results;


  const locationOptions = locationsOptionsList?.length
  ? [].concat(locationsOptionsList
      .map((item) => ({ value: item.location_address, label: item.location_address.replace(/\^/g, ","), category: "location" }))
      .filter((item) => item.value))
  : [];

  const [selectedValues, setSelectedValues] = useState(selectedFilters);

  

  const history = useHistory();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const mergedFilters = {
      location:
        selectedValues["location"] || filterValuesForRequest["location"],
    };

    setSelectedFilters(mergedFilters);

    let params = new URLSearchParams(history.location.search);

    params.delete("location_address");

    if (mergedFilters.location?.length > 0) {
      mergedFilters.location.forEach((itm) => {
        params.append("location_address", itm.value);
      });
    }

    history.replace({
      search: params.toString(),
    });
    handleSubmit(mergedFilters);
    onClose();
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };




  const colourStyles = {
    placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isSelected ? "#D9ECFF" : "white",
        display: "flex",
      };
    },
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <Card>
          <HeaderRow>
            <FilterMenuTitle>Filters </FilterMenuTitle>
            <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
          </HeaderRow>

          <div style={{ width: "100%" }}>
            <FormContainer>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <Row>
                    <SelectContainer width="464px">
                      <SelectDropdownTitle>Report Location</SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={locationOptions}
                        value={selectedValues["location"]}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        allowSelectAll={true}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            location: value,
                          })
                        }
                        styles={colourStyles}
                      />
                    </SelectContainer>
                  </Row>

                  <Row justifyContent="flex-end" gap="18px">
                    <Button
                      color="#000000"
                      border="2px solid #CFCFCF"
                      bg="#FFF"
                    >
                      Cancel
                    </Button>
                    <Button bg="#00AEEF" color="#FFFFFF" isPrimary={true}>
                      Apply Filters
                    </Button>
                  </Row>
                </div>
              </form>
            </FormContainer>
          </div>
        </Card>
      </ModalContentContainer>
    </Modal>
  );
};
